









































































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import { get } from 'lodash'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import {
    getCookie,
    setCookie,
} from '@/utils/cookies'
import {
    isInWhitelist,
} from '@/utils/queryString'
import axios from 'axios'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { authenticate } from '@/api/consoleApi/subscriberArea'
import { SubscriberAreaModule } from '@/store/modules/subscriberArea'
import { pSBC } from '@/utils/colors'

@Component({
    name: 'SubscriberAreaLogin',
    components: {
        VsLoader,
        VsContainer,
    },
})
export default class extends Vue {
    private apicalling = false
    private loginStep = 'EMAIL'
    private redirectUrl: any = ''
    private loginData = {
        email: '',
        userMongoId: this.userId,
        recipientId: this.listId,
    }

    $refs: any

    get siteUrl () {
        return AppModule.siteUrl
    }

    get userId () {
        return this.$route.params?.userId || ''
    }

    get listId () {
        return this.$route.query?.lid || ''
    }

    get redirectCookie () {
        return AppModule.redirectCookie
    }

    get is4Dem () {
        return AppModule.consoleConf?.is4Dem
    }

    get logoLogin () {
        return SubscriberAreaModule.logoLogin
    }

    get primaryColor () {
        return SubscriberAreaModule.primaryColor
    }

    get darkerPrimaryColor () {
        if (!this.primaryColor) return ''
        return pSBC(-0.2, this.primaryColor)
    }

    get layoutCssVars () {
        if (this.primaryColor) {
            return `
                :root {
                    --vs-color-primary-800: ${this.primaryColor};
                    --vs-color-primary-900: ${this.darkerPrimaryColor};
                }
            `
        }
        return ``
    }

    private setRedirectUrl () {
        const redirectUrl = get(this.$route, 'query.redirect', getCookie(this.redirectCookie))
        if (isInWhitelist(redirectUrl)) {
            setCookie(this.redirectCookie, redirectUrl, 365)
            this.redirectUrl = redirectUrl
        }
    }

    mounted () {
        this.setFocus()
        this.setRedirectUrl()
    }

    private setFocus () {
        if (typeof this.$refs.autofocus !== 'undefined') {
            this.$refs.autofocus.$children[0].$refs.input.focus()
        }
    }

    private async submitPassword () {
        this.apicalling = true
        try {
            await authenticate(this.loginData)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const status = get(e, 'response.status', '')
                if (status === 429) {
                    this.$root.$vsToast({
                        heading: 'Hai fatto troppi tentativi di login, riprova più tardi.',
                        timeout: 3000,
                        aspect: VsToastAspectEnum.alert,
                    })
                    return
                }
            }
        }
        this.loginStep = 'CHECK_EMAIL_ACTIVATION'
        this.apicalling = false
    }
}
